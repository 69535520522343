import { DeparturesDayRoomOption } from "../Props";
import { isDiscountPriceValid } from "./isDiscountPriceValid";

export const isAnyRoomOptionUnderDiscount = (
  roomOptions: DeparturesDayRoomOption[]
) => {
  return roomOptions.some((room) =>
    room.roomOptionPrices.some((price) => isDiscountPriceValid(price))
  );
};
