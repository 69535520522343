import { DeparturesDayStyle } from "../Props";
import { isThereAnyRoomOnSale } from "./isThereAnyRoomOnSale";

export const isThereAnyStyleOnSale = (
  styles: DeparturesDayStyle[],
  isFamilyTrip: boolean
) => {
  return styles.some(({ rooms }) => {
    if (!rooms) {
      return false;
    }
    return isThereAnyRoomOnSale(rooms, isFamilyTrip);
  });
};
