import { DeparturesDayPrice } from "../Props";
import { isPriceValid } from "./isPriceValid";

export const isDiscountPriceValid = (price: DeparturesDayPrice) => {
  if (!isPriceValid(price)) {
    return false;
  }

  if (
    price.discountedPrice &&
    price.discountedPrice > 0 &&
    typeof price.discountedPrice === "number"
  ) {
    return true;
  }

  return false;
};
