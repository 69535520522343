import { DeparturesDayPrice } from "../Props";
import { Currency, CurrencyCode } from "~~/lib/types/Currency";
import { isEmpty } from "~~/lib/utils/isEmpty";

export const isPriceValid = (price?: DeparturesDayPrice) => {
  if (!price) {
    return false;
  }

  if (isEmpty(price)) {
    return false;
  }

  if (!price.price || typeof price.price !== "number") {
    return false;
  }

  if (!price.currencyCode || typeof price.currencyCode !== "string") {
    return false;
  }

  return Object.values<string>(Currency).includes(
    price.currencyCode.toLowerCase() as CurrencyCode
  );
};
