<template>
  <div
    v-if="destinationLists && destinationLists.length > 0"
    data-cy="destination-promoted-destinations"
  >
    <DestinationList
      v-for="(destinationList, index) of destinationLists"
      :key="index"
      :title="destinationList.title"
      :destinations="destinationList.destinations"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import DestinationList from "../DestinationList/DestinationList.vue";
import { DestinationPromotedDestinationsProps } from "./Props";

export default Vue.extend({
  name: "DestinationPromotedDestinations",
  components: {
    DestinationList,
  },
  props: {
    destinationLists: {
      type: Array as PropType<
        DestinationPromotedDestinationsProps["destinationLists"]
      >,
      required: true,
    },
  },
});
</script>
