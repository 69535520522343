<template>
  <div
    v-if="destinationFacts && Object.keys(destinationFacts).length > 0"
    class="destination-facts"
  >
    <h2>{{ destinationDisplayName }} at a glance</h2>
    <div class="destination-facts__grid-wrapper">
      <div class="l-grid">
        <div
          v-for="(fact, index) in destinationFacts"
          :key="index"
          class="l-grid__cell l-grid__cell--4-col"
        >
          <IconList>
            <DestinationFact v-bind="fact" />
          </IconList>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import IconList from "atlas/src/components/IconList/IconList.vue";
import DestinationFact from "./DestinationFact.vue";
import { DestinationFactsProps } from "./Props";

export default Vue.extend({
  name: "DestinationFacts",
  components: {
    DestinationFact,
    IconList,
  },
  props: {
    destinationDisplayName: {
      type: String,
      required: true,
    },
    destinationFacts: {
      type: Object as PropType<DestinationFactsProps>,
      required: true,
    },
  },
});
</script>
<style lang="scss">
@import "./destination-facts";
</style>
