<template>
  <div data-cy="destination-start-end">
    <TripList
      v-if="showTripList(endLocationTrips)"
      id="tours-to"
      data-anchor-text="Tours to"
      type="utility"
      v-bind="endLocationTrips"
      class="u-margin-top--4 sm:u-margin-top--6"
    />
    <TripList
      v-if="showTripList(startLocationTrips)"
      id="tours-from"
      data-anchor-text="Tours from"
      type="utility"
      v-bind="startLocationTrips"
      class="u-margin-top--4 sm:u-margin-top--6"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TripList from "~/components/TripList/TripList.vue";
import { DestinationPageProps } from "~/components/DestinationPage/Props";
import { TripListProps } from "~/components/TripList/Props";

export default Vue.extend({
  name: "DestinationStartEnd",
  components: {
    TripList,
  },
  props: {
    startLocationTrips: {
      type: Object as PropType<DestinationPageProps["startLocationTrips"]>,
      required: false,
      default: undefined,
    },
    endLocationTrips: {
      type: Object as PropType<DestinationPageProps["endLocationTrips"]>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    showTripList(tripList: TripListProps | undefined) {
      if (tripList === undefined) {
        return false;
      }

      return tripList.trips?.length || tripList.tailorMadeTripCard;
    },
  },
});
</script>
