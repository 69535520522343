import { DeparturesDayRoomOption } from "../Props";
import { isPriceValid } from "./isPriceValid";

export const isThereValidRoomOptionPrice = (
  roomOptions: DeparturesDayRoomOption[]
) => {
  let isValid = false;

  for (const departureRoomOption of roomOptions) {
    if (!Array.isArray(departureRoomOption.roomOptionPrices)) {
      continue;
    }
    for (const roomOptionPrice of departureRoomOption.roomOptionPrices) {
      if (isPriceValid(roomOptionPrice)) {
        isValid = true;
      }
    }
  }

  return isValid;
};
