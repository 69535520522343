export const isAvailabilityBetweenZeroAndFive = (availability: number) => {
  if (!availability) {
    return false;
  }

  if (availability > 0 && availability < 5) {
    return true;
  }

  return false;
};
