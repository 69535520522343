import { DeparturesDayRoom } from "../Props";
import { isAvailable } from "./isAvailable";
import { isFamilyTripWithOneAvailability } from "./isFamilyTripWithOneAvailability";
import { isRoomOptionOnDiscount } from "./isRoomOptionOnDiscount";

export const isThereAnyRoomOnSale = (
  rooms: DeparturesDayRoom[],
  isFamilyTrip: boolean
) => {
  return rooms.some((room) => {
    if (!room.roomOptions) {
      return false;
    }
    return (
      isRoomOptionOnDiscount(room.roomOptions) &&
      isAvailable(room.availability) &&
      !isFamilyTripWithOneAvailability(isFamilyTrip, room.availability) // For a room that belongs to family trip, we don't render pricing message if availability is 1
    );
  });
};
