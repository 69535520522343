<template>
  <div v-if="items && items.length" class="destination-information">
    <h3 class="headline--5 u-margin-bottom--1">
      Learn more about {{ destinationName }}
    </h3>
    <div class="l-grid">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="l-grid__cell l-grid__cell--flex l-grid__cell--4-cols"
      >
        <DestinationInformationItem v-bind="item" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import DestinationInformationItem from "./DestinationInformationItem.vue";
import { DestinationInformationProps } from "./Props";

export default Vue.extend({
  name: "DestinationInformation",
  components: {
    DestinationInformationItem,
  },
  props: {
    items: {
      type: Array as PropType<DestinationInformationProps["items"]>,
      required: true,
    },
    destinationName: {
      type: String as PropType<DestinationInformationProps["destinationName"]>,
      required: true,
    },
  },
});
</script>
