import { DeparturesDayPrice } from "../Props";
import { isDiscountPriceValid } from "./isDiscountPriceValid";

export const isPriceDiscounted = (price: DeparturesDayPrice) => {
  if (!isDiscountPriceValid(price)) {
    return false;
  }

  if (
    price.price &&
    price.price > 0 &&
    price.discountedPrice &&
    price.discountedPrice > 0 &&
    price.discountedPrice < price.price
  ) {
    return true;
  }

  return false;
};
