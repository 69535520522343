<template>
  <Page v-bind="page">
    <template #alerts>
      <ErrorBoundary
        v-if="travelAlert"
        class="l-container sm:u-margin-top--1 u-margin-top--0-5"
      >
        <TravelAlert v-bind="travelAlert" />
      </ErrorBoundary>
    </template>
    <template #default>
      <ErrorBoundary
        v-if="introduction"
        class="
          l-container l-container--small
          u-margin-top--2
          sm:u-margin-top--6
        "
      >
        <Introduction
          v-if="introduction"
          v-bind="introduction"
          id="overview"
          data-anchor-text="Overview"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <SubNavigation class="u-margin-top--4" />
      </ErrorBoundary>
      <ErrorBoundary v-if="dealPromotion" class="l-container">
        <DealPromotionCard
          v-bind="dealPromotion"
          class="u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
      <div class="l-container">
        <ErrorBoundary>
          <DepartureFeaturedList
            id="travel-deals"
            class="u-margin-top--4 sm:u-margin-top--6"
            :destinationName="destinationDisplayName"
            :destinationType="destinationType"
            data-anchor-text="Travel deals"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <DestinationStartEnd
            v-if="showStartEndLocationTrips"
            :start-location-trips="startLocationTrips"
            :end-location-trips="endLocationTrips"
          />
          <template v-else>
            <TripList
              v-if="
                countriesVisitedTrips?.trips?.length ||
                countriesVisitedTrips?.tailorMadeTripCard
              "
              id="our-trips"
              type="utility"
              data-anchor-text="Our trips"
              v-bind="countriesVisitedTrips"
              class="u-margin-top--4 sm:u-margin-top--6"
            />
          </template>
        </ErrorBoundary>
        <ErrorBoundary>
          <HighlightList
            v-if="destinationHighlights && destinationHighlights?.length >= 1"
            id="highlights"
            :highlights="destinationHighlights"
            data-anchor-text="Highlights"
            :highlightsTitle="`${destinationDisplayName} highlights`"
            class="u-margin-top--4 sm:u-margin-top--6"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <BoatSection
            v-if="
              promotedBoats?.boats?.boat?.length &&
              promotedBoats.boats.boat.length > 0
            "
            id="boats"
            :title="promotedBoats.title"
            :description="promotedBoats.description"
            :boats="promotedBoats.boats.boat"
            class="u-margin-top--4 sm:u-margin-top--6"
            data-anchor-text="Boats"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <Route
            v-if="destinationRoutes"
            v-bind="destinationRoutes"
            id="routes"
            class="u-margin-top--4 sm:u-margin-top--6"
            data-anchor-text="Routes"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ProfileSection
            v-if="leaderProfiles && leaderProfiles?.profiles?.length >= 1"
            id="leader-profiles"
            data-anchor-text="Our people"
            v-bind="leaderProfiles"
            class="u-margin-top--4 sm:u-margin-top--6"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <div
            v-if="uniqueSellingPoints?.length >= 1"
            id="destination-unique-selling-point-list"
            data-cy="destination-unique-selling-point-list"
            class="u-margin-top--4 sm:u-margin-top--6"
            data-anchor-text="Why choose us"
          >
            <h2>Why choose us</h2>
            <UniqueSellingPointList
              :uniqueSellingPoints="uniqueSellingPoints"
            />
          </div>
        </ErrorBoundary>
        <ErrorBoundary>
          <Reviews
            v-if="reviews?.reviewComments.length"
            data-anchor-text="Trip reviews"
            v-bind="reviewAggregate"
            :reviews="reviews"
            class="u-margin-top--4 sm:u-margin-top--6"
          >
            <h2 slot="title">{{ reviews.displayName }} tour reviews</h2>
          </Reviews>
        </ErrorBoundary>
        <ErrorBoundary>
          <BlogCarousel
            v-if="blogCarousel"
            id="articles"
            data-anchor-text="Articles"
            v-bind="blogCarousel"
            class="u-margin-top--4 sm:u-margin-top--6"
            :carouselTitle="`Articles of ${destinationDisplayName}`"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <div
            v-if="promotedCategories && promotedCategories.length > 0"
            id="themes"
            data-anchor-text="Themes"
          >
            <template v-for="(promotedCategory, index) in promotedCategories">
              <CategorySection
                v-if="promotedCategory.categories.length > 0"
                :key="index"
                v-bind="promotedCategory"
                class="u-margin-top--4 sm:u-margin-top--6"
              />
            </template>
          </div>
        </ErrorBoundary>
        <ErrorBoundary v-if="departuresByMonth?.length">
          <DestinationByMonthList
            class="u-margin-top--4 sm:u-margin-top--6"
            :destinationName="destinationDisplayName"
            :months="departuresByMonth"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <DestinationPromotedDestinations
            v-if="promotedDestinations"
            :destinationLists="promotedDestinations.destinationLists"
            class="u-margin-top--4 sm:u-margin-top--6"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <DestinationFacts
            v-if="destinationFacts"
            id="at-a-glance"
            data-anchor-text="At a glance"
            :destinationFacts="destinationFacts"
            :destinationDisplayName="destinationDisplayName"
            class="u-margin-top--4 sm:u-margin-top--6"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <DestinationInformation
            v-if="destinationInformation"
            v-bind="destinationInformation"
            class="u-margin-top--2"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <Faqs
            v-if="faqs"
            id="faq-block"
            ref="faqs"
            data-anchor-text="FAQs"
            :faqTitle="`${destinationDisplayName} travel FAQs`"
            class="u-margin-top--4 sm:u-margin-top--6"
            :faqs="faqs"
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <Video
            v-if="video"
            id="video"
            data-anchor-text="Video"
            v-bind="video"
            class="u-margin-top--4 sm:u-margin-top--6"
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary v-if="purposeSpotlight" class="u-background-color--sand">
        <PurposeSpotlight
          v-bind="purposeSpotlight"
          class="l-container u-margin-top--4 sm:u-margin-top--6"
        />
      </ErrorBoundary>
    </template>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { WithContext, Product } from "schema-dts";
import { DestinationPageProps } from "./Props";
import HighlightList from "~/components/HighlightList/HighlightList.vue";
import Video from "~/components/Video/Video.vue";
import TravelAlert from "~/components/TravelAlert/TravelAlert.vue";
import Page from "~/components/Page/Page.vue";
import BlogCarousel from "~/components/BlogCarousel/BlogCarousel.vue";
import Introduction from "~/components/Introduction/Introduction.vue";
import DestinationFacts from "~/components/DestinationFacts/DestinationFacts.vue";
import DestinationInformation from "~/components/DestinationInformation/DestinationInformation.vue";
import DepartureFeaturedList from "~/components/DepartureFeaturedList/DepartureFeaturedList.vue";
import Faqs from "~/components/Faqs/Faqs.vue";
import TripList from "~/components/TripList/TripList.vue";
import Reviews from "~/components/Reviews/Reviews.vue";
import DealPromotionCard from "~/components/DealPromotionCard/DealPromotionCard.vue";
import SubNavigation from "~/components/SubNavigation/SubNavigation.vue";
import PurposeSpotlight from "~/components/PurposeSpotlight/PurposeSpotlight.vue";
import DestinationPromotedDestinations from "~/components/DestinationPromotedDestinations/DestinationPromotedDestinations.vue";
import Route from "~/components/Route/Route.vue";
import BoatSection from "~/components/BoatSection/BoatSection.vue";
import CategorySection from "~/components/CategorySection/CategorySection.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import DestinationStartEnd from "~/components/DestinationStartEnd/DestinationStartEnd.vue";
import UniqueSellingPointList from "~/components/UniqueSellingPointList/UniqueSellingPointList.vue";
import { UniqueSellingPointProps } from "~/components/UniqueSellingPoint/Props";
import ProfileSection from "~/components/ProfileSection/ProfileSection.vue";
import DestinationByMonthList from "~/components/DestinationByMonthList/DestinationByMonthList.vue";
import { DealPromotionCardProps } from "~/components/DealPromotionCard/Props";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Page.Destination],
});

export default Vue.extend({
  name: "DestinationPage",
  components: {
    DestinationStartEnd,
    Page,
    BlogCarousel,
    Introduction,
    DestinationFacts,
    DestinationInformation,
    Faqs,
    Video,
    TripList,
    Reviews,
    DepartureFeaturedList,
    DealPromotionCard,
    SubNavigation,
    PurposeSpotlight,
    DestinationPromotedDestinations,
    UniqueSellingPointList,
    Route,
    ErrorBoundary,
    CategorySection,
    HighlightList,
    BoatSection,
    ProfileSection,
    TravelAlert,
    DestinationByMonthList,
  },
  props: {
    destinationDisplayName: {
      type: String as PropType<DestinationPageProps["destinationDisplayName"]>,
      required: true,
    },
    page: {
      type: Object as PropType<DestinationPageProps["page"]>,
      required: true,
    },
    introduction: {
      type: Object as PropType<DestinationPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    destinationFacts: {
      type: Object as PropType<DestinationPageProps["destinationFacts"]>,
      required: false,
      default: undefined,
    },
    destinationInformation: {
      type: Object as PropType<DestinationPageProps["destinationInformation"]>,
      required: false,
      default: undefined,
    },
    faqs: {
      type: Array as PropType<DestinationPageProps["faqs"]>,
      required: false,
      default: undefined,
    },
    video: {
      type: Object as PropType<DestinationPageProps["video"]>,
      required: false,
      default: undefined,
    },
    countriesVisitedTrips: {
      type: Object as PropType<DestinationPageProps["countriesVisitedTrips"]>,
      required: false,
      default: undefined,
    },
    showStartEndLocationTrips: {
      type: Boolean as PropType<
        DestinationPageProps["showStartEndLocationTrips"]
      >,
      required: true,
      default: false,
    },
    startLocationTrips: {
      type: Object as PropType<DestinationPageProps["startLocationTrips"]>,
      required: false,
      default: undefined,
    },
    endLocationTrips: {
      type: Object as PropType<DestinationPageProps["endLocationTrips"]>,
      required: false,
      default: undefined,
    },
    reviewAggregate: {
      type: Object as PropType<DestinationPageProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<DestinationPageProps["reviews"]>,
      required: false,
      default: undefined,
    },
    blogCarousel: {
      type: Object as PropType<DestinationPageProps["blogCarousel"]>,
      required: false,
      default: undefined,
    },
    purposeSpotlight: {
      type: Object as PropType<DestinationPageProps["purposeSpotlight"]>,
      required: false,
      default: undefined,
    },
    promotedDestinations: {
      type: Object as PropType<DestinationPageProps["promotedDestinations"]>,
      required: false,
      default: undefined,
    },
    departuresByMonth: {
      type: Array as PropType<DestinationPageProps["departuresByMonth"]>,
      required: false,
      default: undefined,
    },
    promotedCategories: {
      type: Array as PropType<DestinationPageProps["promotedCategories"]>,
      required: false,
      default: undefined,
    },
    uniqueSellingPoints: {
      type: Array as PropType<UniqueSellingPointProps[]>,
      required: false,
      default: undefined,
    },
    destinationType: {
      type: String as PropType<DestinationPageProps["destinationType"]>,
      required: false,
      default: undefined,
    },
    destinationHighlights: {
      type: Array as PropType<DestinationPageProps["destinationHighlights"]>,
      required: true,
      default: undefined,
    },
    destinationRoutes: {
      type: Object as PropType<DestinationPageProps["destinationRoutes"]>,
      required: false,
      default: undefined,
    },
    promotedBoats: {
      type: Object as PropType<DestinationPageProps["promotedBoats"]>,
      required: false,
      default: undefined,
    },
    leaderProfiles: {
      type: Object as PropType<DestinationPageProps["leaderProfiles"]>,
      required: false,
      default: undefined,
    },
    travelAlert: {
      type: Object as PropType<DestinationPageProps["travelAlert"]>,
      required: false,
      default: undefined,
    },
  },

  data(): {
    dealPromotion: DealPromotionCardProps | undefined;
  } {
    return {
      dealPromotion: undefined,
    };
  },
  async created() {
    this.updateAggregateSchema();

    if (process.client) {
      try {
        this.dealPromotion = await $fetch<DealPromotionCardProps | undefined>(
          "/api/nuxt/deal/promotion",
          {
            params: {
              localeCode: this.$i18n.locale,
              localeIso: this.$i18n.localeProperties.iso,
              destinationSlugs: JSON.stringify([
                `/${this.$route.params.pathMatch}`,
              ]),
            },
          }
        );
      } catch (error) {
        logger.error(
          "Fetching deal promotion failed on Destination page",
          error
        );
      }
    }
  },
  mounted() {
    if (this.$route.hash === "#faqs" && this.$refs.faqs) {
      this.$nextTick(() => {
        const faqBlock = document.getElementById(
          "anchor-navigation__navigation-link-faq-block"
        );
        if (faqBlock) {
          faqBlock.click();
        }
      });
    }
  },
  methods: {
    updateAggregateSchema() {
      (this.page?.jsonld as unknown as WithContext<Product>[]).map((jsonLd) => {
        if (
          jsonLd["@type"] === "Product" &&
          this.reviewAggregate?.averageRating &&
          this.reviewAggregate?.reviewsCount
        ) {
          jsonLd.aggregateRating = {
            "@type": "AggregateRating",
            ratingValue: this.reviewAggregate?.averageRating,
            reviewCount: this.reviewAggregate?.reviewsCount,
          };
        }

        return jsonLd;
      });
    },
  },
});
</script>
