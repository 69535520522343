import { DeparturesDayRoomOption } from "../Props";
import { isPriceDiscounted } from "./isPriceDiscounted";

export const isRoomOptionOnDiscount = (
  roomOptions: DeparturesDayRoomOption[]
): boolean => {
  if (!roomOptions || !Array.isArray(roomOptions)) {
    return false;
  }

  return roomOptions.some(({ roomOptionPrices }) => {
    if (!roomOptionPrices) {
      return false;
    }

    return roomOptionPrices.some((roomOptionPrice) => {
      return isPriceDiscounted(roomOptionPrice);
    });
  });
};
