import { DeparturesDayRoom } from "../Props";
import { isAvailable } from "./isAvailable";
import { isFamilyTripWithOneAvailability } from "./isFamilyTripWithOneAvailability";
import { isThereValidRoomOptionPrice } from "./isThereValidRoomOptionPrice";

export const isRoomValid = (
  isFamilyTrip: boolean,
  departureRoom?: DeparturesDayRoom
) => {
  if (!departureRoom) {
    return false;
  }

  return (
    isAvailable(departureRoom.availability) &&
    isThereValidRoomOptionPrice(departureRoom.roomOptions) &&
    !isFamilyTripWithOneAvailability(isFamilyTrip, departureRoom.availability)
  );
};
