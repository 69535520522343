<template>
  <div class="destination-information-item">
    <Tile
      class="tile--horizontal tile--text-left tile--small"
      :title="title"
      :icon="getIconByType(type)"
      @click="openModal"
    />
    <Modal
      :show-modal="showModal"
      :description="title"
      :seo="true"
      @close="closeModal"
    >
      <template slot="heading">
        {{ title }}
      </template>
      <template slot="content">
        <div class="destination-information-item__modal-image-wrapper">
          <Imagery
            v-if="image"
            class="destination-information-item__modal-image"
            :lazy-src="image.src"
            :lazy-srcset="image.srcSet"
            type="static"
            :width="image.width"
            :height="image.height"
            :alt="image.alt"
          ></Imagery>
        </div>
        <JsonRichText
          class="
            destination-information-item__description
            u-padding-top--1
            u-padding-bottom--1-5
          "
          v-bind="description"
        />
      </template>
      <template slot="footer">
        <Button class="button button--secondary" :onClick="closeModal"
          >Close</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Button from "atlas/src/components/Button/Button.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import JsonRichText from "../JsonRichText/JsonRichText.vue";
import { DestinationInformationItemProps } from "./Props";

type Data = {
  showModal: boolean;
  destinationInformationIconMap: Record<string, string>;
};

export default Vue.extend({
  name: "DestinationInformationItem",
  components: {
    Tile,
    Modal,
    Button,
    Imagery,
    JsonRichText,
  },
  props: {
    title: {
      type: String as PropType<DestinationInformationItemProps["title"]>,
      required: true,
    },
    type: {
      type: String as PropType<DestinationInformationItemProps["type"]>,
      required: true,
    },
    description: {
      type: Object as PropType<DestinationInformationItemProps["description"]>,
      required: true,
    },
    image: {
      type: Object as PropType<DestinationInformationItemProps["image"]>,
      required: false,
      default: undefined,
    },
  },
  data(): Data {
    return {
      showModal: false,
      destinationInformationIconMap: {
        "best time to visit": "calendar",
        "culture and customs": "hello",
        "eating and drinking": "fork-knife",
        "festival and events": "festival",
        "food and drink": "info-circle",
        "further reading": "info-circle-outline",
        "geography and environment": "map-outline",
        "history and government": "quill-paper",
        shopping: "shopping-bag",
        "top picks": "mountains",
        other: "info-circle",
      },
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    getIconByType(type: string) {
      const defaultIcon = "info-circle";

      if (!type) {
        return defaultIcon;
      }

      return (
        (this.destinationInformationIconMap as Record<string, string>)[
          type.toLowerCase()
        ] || defaultIcon
      );
    },
  },
});
</script>
<style lang="scss">
@import "./destination-information-item";
</style>
