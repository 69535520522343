<template>
  <div
    v-if="destinations && destinations.length > 0"
    data-cy="promoted-destination"
  >
    <h2
      data-cy="promoted-destination-display-name"
      class="u-margin-top--4 sm:u-margin-top--6"
    >
      {{ title }}
    </h2>
    <div
      v-if="destinations.length <= carouselBreakValue"
      class="l-grid"
      data-cy="destination-cards"
    >
      <div
        v-for="(destination, index) of destinations"
        :key="index"
        class="l-grid__cell l-grid__cell--flex"
        :class="gridClass"
      >
        <DestinationCard
          :title="destination.title"
          :image="destination.image"
          :link="destination.link"
        />
      </div>
    </div>
    <CarouselStatic
      v-if="destinations.length > carouselBreakValue"
      :description="title"
      data-cy="static-carousel"
    >
      <CarouselStaticItem
        v-for="(destination, index) of destinations"
        :key="index"
      >
        <DestinationCard
          :title="destination.title"
          :image="destination.image"
          :link="destination.link"
        />
      </CarouselStaticItem>
    </CarouselStatic>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import CarouselStatic from "atlas/src/components/CarouselStatic/CarouselStatic.vue";
import CarouselStaticItem from "atlas/src/components/CarouselStatic/CarouselStaticItem.vue";
import DestinationCard from "../DestinationCard/DestinationCard.vue";
import { DestinationListProps } from "./Props";
export default Vue.extend({
  name: "DestinationList",
  components: {
    CarouselStaticItem,
    CarouselStatic,
    DestinationCard,
  },
  props: {
    title: {
      type: String as PropType<DestinationListProps["title"]>,
      required: true,
    },
    destinations: {
      type: Array as PropType<DestinationListProps["destinations"]>,
      required: true,
    },
  },
  data() {
    return {
      carouselBreakValue: 3,
    };
  },
  computed: {
    gridClass(): string {
      return this.destinations.length === 4
        ? "l-grid__cell--3-col-desktop "
        : " l-grid__cell--4-col-tablet l-grid__cell--4-col-desktop";
    },
  },
});
</script>
