<template>
  <IconItem v-if="icon && title && value" :iconName="icon">
    <h3 class="headline--5 u-margin-bottom--0 u-margin-top--0">{{ title }}</h3>
    <template v-if="Array.isArray(value)">
      <p v-for="(val, index) in value" :key="index">
        {{ val }}
      </p>
    </template>
    <p v-else>{{ value }}</p>
  </IconItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import IconItem from "atlas/src/components/IconList/IconItem.vue";
import "atlas/src/components/Icon/icons/city";
import "atlas/src/components/Icon/icons/people";
import "atlas/src/components/Icon/icons/currency";
import "atlas/src/components/Icon/icons/globe-outline";
import "atlas/src/components/Icon/icons/clock-outline";
import "atlas/src/components/Icon/icons/mobile-phone";
import "atlas/src/components/Icon/icons/power-plug";
import { DestinationFactProps } from "./Props";

export default Vue.extend({
  name: "DestinationFact",
  components: {
    IconItem,
  },
  props: {
    icon: {
      type: String as PropType<DestinationFactProps["icon"]>,
      required: true,
    },
    title: {
      type: String as PropType<DestinationFactProps["title"]>,
      required: true,
    },
    value: {
      type: String as PropType<DestinationFactProps["value"]>,
      required: true,
    },
  },
});
</script>
