import { TranslatedRoomType } from "../Props";

export const getTranslatedRoomType = (roomType: string) => {
  const index = Object.keys(TranslatedRoomType).indexOf(roomType.toUpperCase());

  if (index < 0) {
    return roomType;
  }

  return Object.values(TranslatedRoomType)[index];
};
