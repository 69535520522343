import { DeparturesDayStyle } from "../Props";

export const isAllRoomOnRequest = (styles: DeparturesDayStyle[]) => {
  if (!styles || !styles.length || !Array.isArray(styles)) {
    return false;
  }

  return styles.every(({ rooms }) => {
    if (!rooms || !rooms.length || !Array.isArray(rooms)) {
      return false;
    }
    return rooms.every(
      (room) => room?.isOnRequest && typeof room.isOnRequest === "boolean"
    );
  });
};
