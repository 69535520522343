import { DeparturesDayStyle } from "../Props";
import { isFamilyTripWithOneAvailability } from "./isFamilyTripWithOneAvailability";

export const isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip = (
  isFamilyTrip: boolean,
  style: DeparturesDayStyle
) => {
  if (!style) {
    return false;
  }

  return style.rooms.every((room) =>
    isFamilyTripWithOneAvailability(isFamilyTrip, room.availability)
  );
};
