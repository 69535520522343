<template>
  <TileLinks
    data-cy="destination-by-month-list"
    :title="`${destinationName} departures by month`"
    :links="months"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DestinationByMonthListProps } from "./Props";
import TileLinks from "~/components/TileLink/TileLinks.vue";

export default Vue.extend({
  name: "DestinationByMonthList",
  components: { TileLinks },
  props: {
    destinationName: {
      type: String as PropType<DestinationByMonthListProps["destinationName"]>,
      required: true,
    },
    months: {
      type: Array as PropType<DestinationByMonthListProps["months"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
