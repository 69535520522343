<template>
  <Card :image="image" :href="$link.create(link)" data-cy="destination-card">
    <template slot="heading"> {{ title }} </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import { DestinationCardProps } from "./props";

export default Vue.extend({
  name: "DestinationCard",
  components: {
    Card,
  },
  props: {
    title: {
      type: String as PropType<DestinationCardProps["title"]>,
      required: true,
    },
    link: {
      type: String as PropType<DestinationCardProps["link"]>,
      required: true,
    },
    image: {
      type: Object as PropType<DestinationCardProps["image"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
