export const isFamilyTripWithOneAvailability = (
  isFamilyTrip: boolean,
  availability: number
) => {
  if (availability === 1 && isFamilyTrip) {
    return true;
  }

  return false;
};
