import { DeparturesDayStyle } from "../Props";
import { isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip } from "./isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip";

export const isAllRoomsInStylesOneSpaceLeftAndIsFamilyTrip = (
  isFamilyTrip: boolean,
  styles: DeparturesDayStyle[]
) => {
  if (!Array.isArray(styles) || !styles || !styles.length) {
    return false;
  }

  return styles.every((style) => {
    return isAllRoomsInStyleOneSpaceLeftAndIsFamilyTrip(isFamilyTrip, style);
  });
};
